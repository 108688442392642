import { format } from 'date-fns';

export const VYSTEMBLUE = '#0285c7';

export const iconClassName = 'hover:text-[#0285c7] hover:scale-110 transition-all';

export const mbwCustomTemplateOrgId = '6548c8c39d454d210d309b63';
export const vbwCustomTemplateOrgId = '623c1ebf2bf9ebd223cf5e1d';

// This date is taken from the time this fix went live
// https://github.com/mmmaxi/vystem-platform/issues/11050
// all emails sent after this date have a renderedHtml field and are rendered correctly in the preview
export const mailPreviewAvailableDate = '2024-06-19T11:49:02.842Z';
